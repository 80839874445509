// import Rellax from "rellax"
import AOS from 'aos';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import Swiper, { Navigation, Autoplay, EffectFade, Parallax, Thumbs } from 'swiper';
import 'public/js/header.js';
import scrollSpy from 'simple-scrollspy';

Swiper.use([Navigation, Autoplay, EffectFade, Parallax, Thumbs]);

// JavaScript
$(document).ready( function () {
  AOS.init();

  $('a[href*="#"]')
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        &&
        location.hostname == this.hostname
      ) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          event.preventDefault();
          $('html, body').animate({
            scrollTop: target.offset().top - 78
          }, 1000, function () {
            var $target = $(target);
            $target.focus();
            if ($target.is(":focus")) {
              return false;
            } else {
              $target.attr('tabindex', '-1');
              $target.focus();
            };
          });
        }
      }
    });

  const options = {
    sectionClass: '.scrollspy',
    menuActiveTarget: '.scrollspy-link',
    offset: 100,
  };
  scrollSpy('#about_scrollspy', options);

  var indexBanner = new Swiper('#indexBanner', {
    effect: 'fade',
    autoplay: {
      delay: 5000,
    },
    speed: 1500,
    loop: true,
    parallax: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  var indexProducts = new Swiper('#indexProducts', {
    grabCursor: true,
    slidesPerView: 1.3,
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      680: {
        slidesPerView: 2.5,
        spaceBetween: 20,
      },
      900: {
        slidesPerView: 3.6,
        spaceBetween: 28,
      },
      2010: {
        slidesPerView: 5.5,
        spaceBetween: 35,
      },
    },
  });

  var indexIndustry = new Swiper('#indexIndustry', {
    grabCursor: true,
    loop: true,
    autoplay: {
      delay: 3500,
    },
    speed: 2000,
    centeredSlides: true,
    slidesPerView: 1.6,
    spaceBetween: 0,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      680: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 5,
      },
    },
  });

  var application_size = $('#application .application-items').data('swiper-size');
  var application_loop;
  if (application_size > 3) {
    application_loop = true;
  }else {
    application_loop = false;
  }
  var application = new Swiper('#application', {
    grabCursor: true,
    slidesPerView: 2,
    spaceBetween: 14,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      0: {
        loop: application_loop,
        spaceBetween: 10,
        centeredSlides: true,
      },
      680: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  });

  var productCategory = new Swiper('#productCategory', {
    grabCursor: true,
    speed: 1000,
    slidesPerView: 1.5,
    spaceBetween: 14,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      0: {
        centeredSlides: true,
      },
      680: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  });

  var industry_news = new Swiper('#industry_news', {
    grabCursor: true,
    speed: 1000,
    slidesPerView: 1.2,
    spaceBetween: 14,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      0: {
        centeredSlides: true,
      },
      680: {
        centeredSlides: true,
        slidesPerView: 2.3,
      },
      900: {
        slidesPerView: 3,
      },
    },
  });

  var productDetailsThumbs = new Swiper('#productDetailsThumbs', {
    spaceBetween: 10,
    slidesPerView: 6,
    direction: 'horizontal',
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    breakpoints: {
      680: {
        direction: 'vertical',
        slidesPerView: 6,
      },
      1400: {
        direction: 'vertical',
        slidesPerView: 7,
      },
    }
  })

  var productDetails = new Swiper('#productDetails', {
    speed: 1000,
    spaceBetween: 0,
    thumbs: {
      swiper: productDetailsThumbs,
    }
  });

  //scroll top
  $('.back-to-top').click(function(event){
    $("html,body").animate({scrollTop: 0}, 1000);
  });

  // page-menu
  var windowWidth = $(window).width();

  if (windowWidth <= 900 && $('.page-group-menu').length >= 1) {
    var item = $('.menu-item.is-active').width();
    if ($('.menu-item').hasClass('is-active')) {
      var item_left_size = $('.menu-item.is-active').offset().left;
      if (item_left_size > windowWidth/2 - item/2) {
        var scrollTo = item_left_size - windowWidth/2 + item/2;
        $('.page-group-menu').scrollLeft(scrollTo);
      };
    }
  }

  $('[data-toggle=collapse]').on('click', function(){
    var controls = $(this).attr('aria-controls');
    var collapse_expanded = $(this).attr('aria-expanded');
    var collapse_content = $('[aria-label='+ controls +']');
    $(this).toggleClass('is-active');
    collapse_content.toggleClass('is-active');
    if (collapse_expanded === "true") {
      collapse_content.hide("fast");
      $(this).attr('aria-expanded', false);
    }else {
      $(this).attr('aria-expanded', true);
      collapse_content.show("fast");
    }
  });

  if ('.accordion') {
    $(".accordion [data-toggle=collapse]:first").trigger("click");
  }

  // table responsive
  if ($('.custom-editor table').length >= 1) {
    $('.custom-editor table').wrap('<div class="table-responsive"/></div>');
  }

  $('#float_contact_us_btn').on('click', function() {
    $('#float_contact_us_content').show('fast');
  });

  $('#float_contact_us_content .close').on('click', function() {
    $('#float_contact_us_content').hide('fast');
  });

  //file upload
  function fileValidate(fileSize, maxSize, fileInput, fileText, fileName) {
    if (fileSize) {
      if (fileSize <= maxSize) {
        fileText.html(fileName);
        fileText.addClass('active');
        return true;
      } else {
        fileInput.val('');
        fileText.html('');
        fileText.removeClass('active');
        fileText.html(fileText.data('file-note'));
        return alert('檔案須小於5MB');
      };
    };
  };

  $('.form-control-file').on('change', function (e) {
    const fileName = $(this).val().replace(/C:\\fakepath\\/i, '');
    const fileSize = this.files[0].size;
    const maxSize = 5000000;
    const fileInput = $(this);
    const fileText = $(this).siblings('.input-file-text');
    fileValidate(fileSize, maxSize, fileInput, fileText, fileName);
  });

  $('.file-reset').on('click', function (e) {
    let fileText = $(this).siblings('.input-file-text');
    $(this).siblings('.form-control-file').val('');
    fileText.html(fileText.data('file-note'));
    fileText.removeClass('active');
  });

} );
