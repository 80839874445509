$(document).ready(function () {
  $(".header-menu-btn").on("click", function() {
    var id = $(this).data('target');
    $(this).toggleClass('is-active');
    $(id).toggleClass('is-active');
    // console.log(id);
  });

  $("[data-toggle=dropdown]").on("click", function() {
    var id = $(this).data('target');
    $(this).toggleClass('is-active');
    $("[aria-label="+id+"]").toggleClass('is-active');
    // console.log(id);
  });

  $("[aria-label=headerProduct] .menu-dropdown-item").on("mouseenter mouseleave", function() {
    var productImg = $(this).data('item-img');
    $("#headerProductImg").attr("src", productImg);
  });
});
