import "@fontsource/comfortaa/300.css"
import "@fontsource/comfortaa/400.css"
import "@fontsource/comfortaa/700.css"
import "@fontsource/figtree/400.css"
import "@fontsource/figtree/500.css"
import "@fontsource/figtree/700.css"
import "@fontsource/figtree/800.css"
import "@fontsource/noto-sans-tc/300.css"
import "@fontsource/noto-sans-tc/400.css"
import "@fontsource/noto-sans-tc/500.css"
import "@fontsource/noto-sans-tc/600.css"
