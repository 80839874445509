import 'public/js/font'
import 'sweetalert2/dist/sweetalert2.min.css';
import 'aos/dist/aos.css';
import 'bootstrap-table/dist/extensions/fixed-columns/bootstrap-table-fixed-columns.min.css';
import 'bootstrap-table/dist/bootstrap-table.min.css'
import 'public/style.scss';
import 'core-js';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import Cookies from 'js-cookie';
import 'bootstrap-table/dist/bootstrap-table.min.js';

import 'public/main'
import Rails from "@rails/ujs"
Rails.start();

window.jQuery = $;
window.$ = $;
window.Swal = Swal;
window.Cookies = Cookies;
